const firebaseConfig = {
  apiKey: "AIzaSyCu23k1O-QxoT_7O5LtvD0GdqrsRXpmfzM",
  authDomain: "devsu-testing-system.firebaseapp.com",
  projectId: "devsu-testing-system",
  storageBucket: "devsu-testing-system.appspot.com",
  messagingSenderId: "755339169534",
  appId: "1:755339169534:web:3106668bde6fe134b79faf",
  measurementId: "G-F4XVGQTB0K",
};

export default firebaseConfig;
