import { buildSchema } from "@camberi/firecms";
import { Group } from "../types";

const groupSchema = buildSchema<Group>({
  name: "Group",
  properties: {
    name: {
      title: "Name",
      dataType: "string",
      validation: { required: true },
    },
    tests: {
      title: "Tests",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "tests",
      },
    },
  },
});

export default groupSchema;
