import { buildCollection } from "@camberi/firecms";
import testPageSchema from "../schemas/testPageSchema";
import { TestPage } from "../types";

const testPageCollection = buildCollection<TestPage>({
  relativePath: "testPages",
  schema: testPageSchema,
  name: "Test Page",
});

export default testPageCollection;
