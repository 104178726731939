import { buildCollection } from "@camberi/firecms";
import groupSchema from "../schemas/groupSchema";

const groupCollection = buildCollection({
  relativePath: "groups",
  schema: groupSchema,
  name: "Group",
});

export default groupCollection;
