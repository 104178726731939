import { buildProperty, buildSchema } from "@camberi/firecms";
import { Test } from "../types";

const testSchema = buildSchema<Test>({
  name: "Test",
  properties: {
    title: {
      title: "Title",
      dataType: "string",
      validation: { required: true },
    },
    subtitle: {
      title: "Subtitle",
      dataType: "string",
      validation: { required: true },
    },
    startAt: {
      title: "Start at",
      dataType: "timestamp",
      validation: { required: true },
    },
    timeLimitInMinutes: {
      title: "Time limit",
      description: "Time limit in minutes",
      dataType: "number",
      validation: { required: true },
    },
    image: buildProperty({
      title: "Image",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          acceptedFiles: ["image/*"],
        },
      },
    }),
    status: {
      title: "Status",
      dataType: "string",
      validation: { required: true },
      config: {
        enumValues: {
          unavailable: "Unavailable",
          available: "Available",
          finalized: "Finalized",
        },
      },
    },
    leaderboard: {
      title: "Leaderboard Settings",
      dataType: "map",
      validation: { required: true },
      properties: {
        winnersLimit: {
          title: "Winners Limit",
          dataType: "number",
          validation: { required: true },
        },
        segmentBy: {
          title: "Segment by",
          dataType: "string",
          validation: { required: true },
        },
        groupBy: {
          title: "Group by",
          dataType: "string",
        },
        detailFields: {
          title: "Detail fields",
          dataType: "array",
          validation: { min: 1 },
          of: {
            dataType: "string",
          },
        },
      },
    },
  },
});

export default testSchema;
