import { buildCollection } from "@camberi/firecms";
import testSchema from "../schemas/testSchema";
import { Test } from "../types";
import testPageCollection from "./testPageCollection";

const testCollection = buildCollection<Test>({
  relativePath: "tests",
  schema: testSchema,
  name: "Test",
  subcollections: [testPageCollection],
});

export default testCollection;
