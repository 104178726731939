import { buildCollection } from "@camberi/firecms";
import taskSchema from "../schemas/taskSchema";
import { Task } from "../types";

const taskCollection = buildCollection<Task>({
  relativePath: "tasks",
  schema: taskSchema,
  name: "Task",
});

export default taskCollection;
