import { buildSchema } from "@camberi/firecms";
import { Task } from "../types";
import { generateUUID } from "../utils/functions";

const taskSchema = buildSchema<Task>({
  name: "Task",
  properties: {
    statement: {
      title: "Statement",
      dataType: "string",
      validation: { required: true },
      config: {
        multiline: true,
        markdown: true,
      },
    },
    requiredQuestions: {
      title: "Required questions",
      dataType: "array",
      validation: { required: true },
      of: {
        dataType: "map",
        properties: {
          statement: {
            title: "Statement",
            dataType: "string",
            validation: { required: true },
            config: {
              multiline: true,
              markdown: true,
            },
          },
          type: {
            title: "Type",
            dataType: "string",
            validation: { required: true },
            config: {
              enumValues: {
                text: "Text",
                textArea: "TextArea",
              },
            },
          },
          correctAnswer: {
            title: "CorrectAnswer",
            dataType: "string",
            validation: { required: true },
          },
          scorable: {
            title: "Scorable",
            dataType: "boolean",
          },
          weight: {
            title: "Weight",
            dataType: "number",
            validation: { required: true },
          },
          uid: {
            title: "uid",
            dataType: "string",
            disabled: true && {
              disabledMessage: "Don't edit this field",
            },
          },
        },
      },
    },
    optionalQuestions: {
      title: "Optional questions",
      dataType: "array",
      validation: { required: true },
      of: {
        dataType: "map",
        properties: {
          statement: {
            title: "Statement",
            dataType: "string",
            validation: { required: true },
            config: {
              multiline: true,
              markdown: true,
            },
          },
          type: {
            title: "Type",
            dataType: "string",
            validation: { required: true },
            config: {
              enumValues: {
                text: "Text",
                textArea: "TextArea",
              },
            },
          },
          correctAnswer: {
            title: "Correct answer",
            dataType: "string",
            validation: { required: true },
          },
          scorable: {
            title: "Scorable",
            dataType: "boolean",
          },
          weight: {
            title: "Weight",
            dataType: "number",
            validation: { required: true },
          },
          uid: {
            title: "uid",
            dataType: "string",
            disabled: true && {
              disabledMessage: "Don't edit this field",
            },
          },
        },
      },
    },
    optionalQuestionsToDisplay: ({ values }) => {
      return {
        title: "Optional question to display",
        description: "How many Optional questions wants to display",
        dataType: "number",
        validation: {
          required: true,
          min: 0,
          max: values.optionalQuestions?.length,
        },
      };
    },
    weight: {
      title: "Weight",
      dataType: "number",
      validation: { required: true },
    },
  },
  defaultValues: {
    optionalQuestionsToDisplay: 0,
  },
  onPreSave: ({ values }) => {
    // TODO create custom field for question UID

    values.optionalQuestions.forEach((optionalQuestion) => {
      if (optionalQuestion.uid === null) {
        optionalQuestion.uid = generateUUID();
      }
    });

    values.requiredQuestions.forEach((requiredQuestion) => {
      if (requiredQuestion.uid === null) {
        requiredQuestion.uid = generateUUID();
      }
    });

    return values;
  },
});

export default taskSchema;
