import {
  Authenticator,
  CMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import firebase from "firebase/app";

import "typeface-rubik";
import "typeface-space-mono";

import firebaseConfig from "./config/firebaseConfig";

import groupCollection from "./collections/groupCollection";
import taskCollection from "./collections/taskCollection";
import testCollection from "./collections/testCollection";

const navigation: NavigationBuilder = async ({
  user,
  authController,
}: NavigationBuilderProps) => {
  return {
    collections: [taskCollection, testCollection, groupCollection],
  };
};

const authenticator: Authenticator = async (user?: firebase.User) => {
  const result = await user?.getIdTokenResult();

  const isAdmin = result?.claims.admin!!;

  return isAdmin;
};

function App() {
  return (
    <CMSApp
      name={"Devsu testing system"}
      authentication={authenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
      signInOptions={[firebase.auth.EmailAuthProvider.PROVIDER_ID]}
    />
  );
}

export default App;
