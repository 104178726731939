import { buildSchema } from "@camberi/firecms";
import { TestPage } from "../types";

const testPageSchema = buildSchema<TestPage>({
  name: "Test page",
  properties: {
    tasks: {
      title: "Tasks",
      dataType: "array",
      validation: {
        required: true,
        min: 1,
      },
      of: {
        dataType: "reference",
        collectionPath: "tasks",
      },
    },
    tasksToDisplay: ({ values }) => ({
      title: "Tasks to display",
      dataType: "number",
      validation: {
        required: true,
        min: 1,
        max: values.tasks?.length
      },
    }),
  },
  defaultValues: {
    tasksToDisplay: 1,
  },
});

export default testPageSchema;
